// themecolor
$themecolor: #5473ef;

$primarybtn:#548def;

$white: #fff;

$mate-black: #212121;

$black: #474646;

$gradient-one:#35b3b5;

$gradient-two:#5454ef;

$footer-back-color:#000;

$header-top-color:#000;

$breadcrumbs-color:#000;
